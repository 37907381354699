import {
    CREATE_ANALYSIS_TYPES,
    TRAVEL_MODES,
} from "@app/analysis/state/analysisConfiguration.constants";
import { ANALYSIS_TYPES, MODES_OF_TRAVEL } from "@common/constants/analysis.constants";
import { faDrawPolygon, faListUl } from "@fortawesome/pro-solid-svg-icons";

export const METRICS_PACKAGES_CATEGORIES = {
    MODE_OF_TRAVEL: {
        id: "Mode of Travel",
        types: {
            2007: {
                feature_id: 2007,
                name: "Personal - All Vehicle",
                code: MODES_OF_TRAVEL.ALL_VEHICLES.code,
                featureName: TRAVEL_MODES.ALL_VEHICLES.featureNames,
            },
            2008: {
                feature_id: 2008,
                name: "Commercial - Truck",
                code: MODES_OF_TRAVEL.TRUCK.code,
                featureName: TRAVEL_MODES.TRUCK.featureNames,
            },
            2009: {
                feature_id: 2009,
                name: "Personal - Bike",
                code: MODES_OF_TRAVEL.BICYCLE.code,
                featureName: TRAVEL_MODES.BICYCLE.featureNames,
            },
            2010: {
                feature_id: 2010,
                name: "PersonaL - Pedestrian",
                code: MODES_OF_TRAVEL.PEDESTRIAN.code,
                featureName: TRAVEL_MODES.PEDESTRIAN.featureNames,
            },
            2076: {
                feature_id: 2076,
                name: "Personal - Bus",
                code: MODES_OF_TRAVEL.BUS.code,
                featureName: TRAVEL_MODES.BUS.featureNames,
            },
            2077: {
                feature_id: 2077,
                name: "Personal - Rail",
                code: MODES_OF_TRAVEL.RAIL.code,
                featureName: TRAVEL_MODES.RAIL.featureNames,
            },
        },
    },
    ANALYSIS_TYPES: {
        id: "Analysis Types",
        types: {
            2016: {
                feature_id: 2016,
                name: "Zone Activity Analysis",
                code: ANALYSIS_TYPES.ZA.id,
                featureName: CREATE_ANALYSIS_TYPES.ZA.featureName,
            },
            2017: {
                feature_id: 2017,
                name: "O-D Analysis",
                code: ANALYSIS_TYPES.OD.id,
                featureName: CREATE_ANALYSIS_TYPES.OD.featureName,
            },
            2018: {
                feature_id: 2018,
                name: "O-D Analysis with Middle Filter",
                code: ANALYSIS_TYPES.ODMF.id,
                featureName: CREATE_ANALYSIS_TYPES.ODMF.featureName,
            },
            2019: {
                feature_id: 2019,
                name: "O-D Preset Geographies",
                code: ANALYSIS_TYPES.ODG.id,
                featureName: CREATE_ANALYSIS_TYPES.ODG.featureName,
            },
            2020: {
                feature_id: 2020,
                name: "Top Routes for O-D",
                code: ANALYSIS_TYPES.TROD.id,
                featureName: CREATE_ANALYSIS_TYPES.TOP_ROUTES_OD.featureName,
            },
            2021: {
                feature_id: 2021,
                name: "Top Routes for a Zone",
                code: ANALYSIS_TYPES.TRZA.id,
                featureName: CREATE_ANALYSIS_TYPES.TOP_ROUTES_ZA.featureName,
            },
            2101: {
                feature_id: 2101,
                name: "Estimates 2022 AADT Values",
                code: ANALYSIS_TYPES.AADT2022.id,
                featureName: CREATE_ANALYSIS_TYPES.AADT.aadtTypes.Estimated_2022_AADT.featureName,
            },
            2095: {
                feature_id: 2095,
                name: "Estimates 2021 AADT Values",
                code: ANALYSIS_TYPES.AADT2021.id,
                featureName: CREATE_ANALYSIS_TYPES.AADT.aadtTypes.Estimated_2021_AADT.featureName,
            },
            2029: {
                feature_id: 2029,
                name: "Estimates 2020 AADT Values",
                code: ANALYSIS_TYPES.AADT2020.id,
                featureName: CREATE_ANALYSIS_TYPES.AADT.aadtTypes.Estimated_2020_AADT.featureName,
            },
            2022: {
                feature_id: 2022,
                name: "Estimates 2019 AADT Values",
                code: ANALYSIS_TYPES.AADT2019.id,
                featureName: CREATE_ANALYSIS_TYPES.AADT.aadtTypes.Estimated_2019_AADT.featureName,
            },
            2023: {
                feature_id: 2023,
                name: "Estimates 2018 AADT Values",
                code: ANALYSIS_TYPES.AADT2018.id,
                featureName: CREATE_ANALYSIS_TYPES.AADT.aadtTypes.Estimated_2018_AADT.featureName,
            },
            2024: {
                feature_id: 2024,
                name: "Estimates 2017 AADT Values",
                code: ANALYSIS_TYPES.AADT2017.id,
                featureName: CREATE_ANALYSIS_TYPES.AADT.aadtTypes.Estimated_2017_AADT.featureName,
            },
            2025: {
                feature_id: 2025,
                name: "Estimates 2016 AADT Values",
                code: ANALYSIS_TYPES.AADT2016.id,
                featureName: CREATE_ANALYSIS_TYPES.AADT.aadtTypes.Estimated_2016_AADT.featureName,
            },
            2026: {
                feature_id: 2026,
                name: "Segment Analysis",
                code: ANALYSIS_TYPES.SEGMENT.id,
                featureName: CREATE_ANALYSIS_TYPES.SEGMENT.featureName,
            },
            2027: {
                feature_id: 2027,
                name: "Traffic Diagnostics",
                code: ANALYSIS_TYPES.TD.id,
                featureName: "",
            },
            2028: {
                feature_id: 2028,
                name: "Season & K-Factor Estimation",
                code: ANALYSIS_TYPES.K_FACTOR.id,
                featureName: CREATE_ANALYSIS_TYPES.K_FACTOR_ESTIMATION.featureName,
            },
            2030: {
                feature_id: 2030,
                name: "Turning Movement Counts",
                code: ANALYSIS_TYPES.TMC.id,
                featureName: CREATE_ANALYSIS_TYPES.TMC.featureName,
            },
            2031: {
                feature_id: 2031,
                name: "AADT By Weight",
                code: TRAVEL_MODES.ALL_VEHICLES_BY_WEIGHT.code,
                featureName: TRAVEL_MODES.ALL_VEHICLES_BY_WEIGHT.featureNames[0],
            },
            2032: {
                feature_id: 2032,
                name: "Congestion Management",
                code: ANALYSIS_TYPES.CT.id,
                featureName: CREATE_ANALYSIS_TYPES.CT.featureName,
            },
            2102: {
                feature_id: 2102,
                name: "Roadway_volume",
                code: ANALYSIS_TYPES.RV.id,
                featureName: CREATE_ANALYSIS_TYPES.RV.featureName,
            },
            2103: {
                feature_id: 2103,
                name: "Active Transportation",
                code: ANALYSIS_TYPES.ATM.id,
                featureName: CREATE_ANALYSIS_TYPES.ATM.featureName,
            },
            2104: {
                feature_id: 2104,
                name: "Network Performance",
                code: ANALYSIS_TYPES.NETWORK_PERFORMANCE_SEGMENT.id,
                featureName: CREATE_ANALYSIS_TYPES.NETWORK_PERFORMANCE_SEGMENT.featureName,
            },
            2105: {
                feature_id: 2105,
                name: "Corridor Studies",
                code: ANALYSIS_TYPES.CS.id,
                featureName: CREATE_ANALYSIS_TYPES.CS.featureName,
            },
            2106: {
                feature_id: 2106,
                name: "Network Performance",
                code: ANALYSIS_TYPES.NETWORK_PERFORMANCE_SPOT.id,
                featureName: CREATE_ANALYSIS_TYPES.NETWORK_PERFORMANCE_SPOT.featureName,
            },
        },
    },
} as const;

export const PROJECT_FOLDER_USER_FILTER_PREFERENCES = {
    PROJECT_FOLDER_NAME: "projectFolderName",
    PROJECT_FOLDER_METRICS_PACKAGES: "projectFolderMetricsPackages",
} as const;

export const PROJECT_FOLDER_USER_PREFERENCE_SECTION_NAME = "projectFolders";

export const DEFAULT_PREFERENCE_FILTER_VALUES = {
    [PROJECT_FOLDER_USER_FILTER_PREFERENCES.PROJECT_FOLDER_NAME]: "",
    [PROJECT_FOLDER_USER_FILTER_PREFERENCES.PROJECT_FOLDER_METRICS_PACKAGES]: null,
};

export const MAP_TYPE_OPTIONS = {
    zoneSets: {
        id: "zoneSets",
        name: "Zone Sets",
        btnLabel: "View Details",
        icon: faDrawPolygon,
        propertyKey: "zoneSet",
    },
    analyses: {
        id: "analyses",
        name: "Analysis Zones",
        btnLabel: "View Analysis Details",
        icon: faListUl,
        propertyKey: "analysis",
    },
} as const;

export const MAP_TYPE_OPTIONS_LIST = Object.values(MAP_TYPE_OPTIONS);

// id of 'A La Carte' metrics package
export const A_LA_CARTE_PACKAGE_ID = 120;

// id of 'All Metrics / All Modes' metrics package
export const ALL_METRICS_ALL_MODES_PACKAGE_ID = 113;

export const ALL_METRICS_PACKAGES_TYPE_ID = 0;

export const ALL_METRICS_PACKAGES_TYPE = {
    metrics_package_id: ALL_METRICS_PACKAGES_TYPE_ID,
    metrics_package_name: "All",
    metrics_package_items: [],
};
