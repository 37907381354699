import {
    CALIBRATIONS,
    CREATE_ANALYSIS_TYPES,
    FIFTEEN_MINUTES_BIN_GRANULARITY,
} from "@app/analysis/state/analysisConfiguration.constants";
import {
    collapseMonthsToRanges,
    enabledForTravelModeAndCalibrationCombination,
    getPartialPeriodDisplayName,
    getPartialPeriodsForMonth,
    isPartialDataPeriod,
    isSameMonth,
} from "@app/analysis/state/analysisConfiguration.helpers";
import {
    CENSUS_2020_THRESHOLD_YEAR,
    CVD_TRAVELER_ATTRIBUTES_REQUIRED_YEARS,
    CVD_UPSAMPLED_METRICS_DATES,
    DAY_CODES,
    DAY_CODES_LIST,
    DAY_PARTS,
    DAY_PARTS_KINDS,
    DAY_TYPES,
    DAY_TYPES_KINDS,
    HOUR_12PM_CODE,
    HOURS_CODES,
    TIME_PERIODS_SWITCH_PATTERNS_ON_TRAVEL_MODE_CHANGE,
    TRUCK_VOLUME_YEARS,
    WEEKDAY_TYPES,
    WEEKDAY_TYPES_LIST,
} from "@app/analysis/timePeriods/state/timePeriods.constants";
import { BIKE_PED_MIN_YEAR_WITH_DATA } from "@app/store/staticData/state/staticData.constants";
import { MODES_OF_TRAVEL } from "@common/constants/analysis.constants";
import {
    getIsAllVehiclesOrTruckTravelMode,
    getIsAllVehiclesTravelMode,
    getIsNetworkPerformanceAnalysis,
    getIsPartialMonthsEnabled,
    getIsTMCAnalysis,
} from "@common/helpers/analysis";
import { uniqBy } from "lodash-es";
import moment from "moment";

const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;

export const getIsAllDayPart = dayPart =>
    dayPart.name === DAY_PARTS.ALL_DAY.name &&
    dayPart.start.name === DAY_PARTS.ALL_DAY.start.name &&
    dayPart.end.name === DAY_PARTS.ALL_DAY.end.name;

export const getHourCode = hourName => {
    if (hourName === HOUR_12PM_CODE.name) {
        return HOUR_12PM_CODE.code;
    }
    return HOURS_CODES[hourName]?.code;
};

export const getDayFromShortName = shortName =>
    DAY_CODES_LIST.find(day => day.shortName === shortName);

export const getDayFromCode = dayCode => DAY_CODES_LIST.find(day => day.code === dayCode);

export const getContinuousDateRanges = dataPeriods => {
    const sortedDataPeriods = [...dataPeriods].sort((a, b) => a.startDate - b.startDate);

    const ranges = [];

    if (!sortedDataPeriods.length) return ranges;

    let startDate = sortedDataPeriods[0].startDate;
    let endDate = sortedDataPeriods[0].endDate;
    let openRange = false;

    for (let i = 0; i < sortedDataPeriods.length; i++) {
        const curDataPeriod = sortedDataPeriods[i];
        const prevDataPeriod = sortedDataPeriods[i - 1];

        if (
            !prevDataPeriod ||
            curDataPeriod.startDate - prevDataPeriod.endDate <= DAY_IN_MILLISECONDS
        ) {
            endDate = curDataPeriod.endDate;
            openRange = true;
        } else {
            ranges.push({ startDate, endDate });
            startDate = curDataPeriod.startDate;
            endDate = curDataPeriod.endDate;
            openRange = i === sortedDataPeriods.length - 1;
        }
    }
    // Finish up last item as needed
    if (openRange) {
        ranges.push({ startDate, endDate });
    }
    return ranges;
};

export const dataPeriodsToString = (dataPeriods, dateFormat = "D-MMM-YYYY") => {
    const formatDataPeriodDate = date => moment(date).format(dateFormat);

    return getContinuousDateRanges(dataPeriods)
        .filter(dataPeriod => !!dataPeriod.startDate && !!dataPeriod.endDate)
        .map(
            dataPeriod =>
                `${formatDataPeriodDate(dataPeriod.startDate)} – ${formatDataPeriodDate(
                    dataPeriod.endDate,
                )}`,
        )
        .join(", ");
};

export const dataMonthsToString = dataMonths => {
    return dataMonths.map(dataMonth => dataMonth.display_name).join(", ");
};

export const parseDateRange = dateRange => {
    const parsedDateRange = dateRange.split("-");
    const periodStart = parsedDateRange[0];
    const periodEnd = parsedDateRange[1] || periodStart;

    return {
        startDate: moment(periodStart.trim(), "MM/DD/YYYY").valueOf(),
        endDate: moment(periodEnd.trim(), "MM/DD/YYYY").valueOf(),
    };
};

export const processExcludedDates = (excludedDates, isDraft = false) => {
    if (!excludedDates) return [];

    if (isDraft || Array.isArray(excludedDates)) {
        return excludedDates.map(date => `${date.start_date} - ${date.end_date}`);
    }

    return excludedDates.split(",");
};

export const getParsedExcludedDateRanges = excludedDates =>
    excludedDates?.map(parseDateRange) || [];

// checks that there are some excluded dates in provided month
export const hasExcludedDatesInPeriod = (excludedDates, year, month) => {
    return excludedDates.some(
        dataPeriod =>
            isSameMonth(dataPeriod.startDate, year, month) ||
            isSameMonth(dataPeriod.endDate, year, month),
    );
};

export const isBetweenPartialPeriod = (compareDate, partialPeriod) =>
    moment.utc(compareDate).isBetween(partialPeriod.startDate, partialPeriod.endDate, null, "[]");

export const checkDatesInsidePartialDataPeriod = (partialPeriod, datePeriods) => {
    if (!partialPeriod) return false;

    return datePeriods.some(period => {
        return (
            isBetweenPartialPeriod(period.startDate, partialPeriod) ||
            isBetweenPartialPeriod(period.endDate, partialPeriod)
        );
    });
};

export const convertDataPeriodsToRanges = (dataPeriods, partialDataPeriods, excludedDates) => {
    if (!dataPeriods || !dataPeriods.length) {
        return [];
    }
    const dateRanges = [];
    let periodStart = dataPeriods[0];

    // Check if provided month has excluded dates and it is a partial period
    // If no excluded dates in provided month, month is considered as full
    const isPartialPeriodWithExcludedDates = (year, month) => {
        return (
            hasExcludedDatesInPeriod(excludedDates, year, month) &&
            isPartialDataPeriod(partialDataPeriods, year, month)
        );
    };

    // Check if two months lie nearby
    const checkNearbyPeriods = (prevItem, curItem) => {
        if (isPartialPeriodWithExcludedDates(curItem.year_num, curItem.month_num)) {
            return false;
        }

        return (
            (curItem.month_num === prevItem.month_num + 1 &&
                curItem.year_num === prevItem.year_num) ||
            (curItem.month_num === 1 &&
                prevItem.month_num === 12 &&
                curItem.year_num === prevItem.year_num + 1)
        );
    };

    const addDateRange = (rangeStart, rangeEnd) => {
        const newDateRange = {
            startDate: moment(
                new Date(rangeStart.year_num, rangeStart.month_num - 1, 1),
            ).valueOf(),
            endDate: moment(new Date(rangeEnd.year_num, rangeEnd.month_num, 0)).valueOf(),
        };

        // If no excluded dates in provided period, this means whole partial month should be added as date range
        if (isPartialPeriodWithExcludedDates(rangeStart.year_num, rangeStart.month_num)) {
            const partialPeriodsForMonth = getPartialPeriodsForMonth(
                partialDataPeriods,
                rangeStart.year_num,
                rangeStart.month_num,
            );
            // find partial data period that is not excluded
            const partialDateRange = partialPeriodsForMonth.find(partialPeriod => {
                return !checkDatesInsidePartialDataPeriod(partialPeriod, excludedDates);
            });

            if (partialDateRange) {
                return {
                    startDate: moment(partialDateRange.startDate).valueOf(),
                    endDate: moment(partialDateRange.endDate).valueOf(),
                };
            } else {
                return newDateRange;
            }
        }
        return newDateRange;
    };

    for (let i = 1; i < dataPeriods.length; i++) {
        const prevPeriod = dataPeriods[i - 1];
        const curPeriod = dataPeriods[i];

        if (!checkNearbyPeriods(prevPeriod, curPeriod)) {
            dateRanges.push(addDateRange(periodStart, prevPeriod));
            periodStart = curPeriod;
        }
    }

    dateRanges.push(addDateRange(periodStart, dataPeriods[dataPeriods.length - 1]));

    return dateRanges;
};

export const getDataPeriods = (analysis, partialDataPeriods, excludedDates) => {
    if (analysis.date_format === "date_ranges") {
        //This extra check is needed because we get an undefined date_ranges from server for drafts when date_ranges is empty. (INST-15019)
        return (
            analysis.date_ranges?.map(dateRange => ({
                startDate: moment(dateRange.start_date).startOf("day").valueOf(),
                endDate: moment(dateRange.end_date).endOf("day").valueOf(),
            })) || []
        );
    } else if (analysis.date_format === "data_periods") {
        // In case of old "data_periods" format, convert to new "date_ranges" format
        return convertDataPeriodsToRanges(
            analysis.data_periods,
            partialDataPeriods,
            excludedDates,
        );
    }
    return [];
};

export const getDataMonths = ({ analysis, partialDataPeriods, excludedDates }) => {
    const isDataPeriodsFormat = analysis.date_format === "data_periods";
    const hasDataPeriods = Array.isArray(analysis.data_periods);
    const shouldParseDataMonths =
        (isDataPeriodsFormat && hasDataPeriods) || analysis.is_api_enabled;

    if (!shouldParseDataMonths) {
        return [];
    }
    const isPartialMonthsEnabled = getIsPartialMonthsEnabled();

    return analysis.data_periods.reduce((result, dataMonth) => {
        if (
            isPartialMonthsEnabled &&
            isPartialDataPeriod(partialDataPeriods, dataMonth.year_num, dataMonth.month_num)
        ) {
            const partialPeriodsForMonth = getPartialPeriodsForMonth(
                partialDataPeriods,
                dataMonth.year_num,
                dataMonth.month_num,
            );
            partialPeriodsForMonth.forEach(partialPeriod => {
                // check if there are any excluded periods inside partial data period
                // if so, it means that such period shouldn't be checked in 'Choose data month' section
                if (checkDatesInsidePartialDataPeriod(partialPeriod, excludedDates)) return;

                result.push({
                    display_name: getPartialPeriodDisplayName(partialPeriod),
                    year: dataMonth.year_num,
                    month: dataMonth.month_num,
                });
            });
        } else {
            const displayName = moment([dataMonth.year_num, dataMonth.month_num - 1, 1]).format(
                "MMM'YY",
            );

            result.push({
                display_name: displayName,
                year: dataMonth.year_num,
                month: dataMonth.month_num,
            });
        }

        return result;
    }, []);
};

const generateFifteenMinuteBin = ({ dayPart, start, index }) => {
    // End bin should be 1 minute less.
    const truncatedEndBin = moment(start)
        .add(FIFTEEN_MINUTES_BIN_GRANULARITY - 1, "minutes")
        .format("HHmm");

    return `${dayPart.name}_${index}|8${start.format("HHmm")}${truncatedEndBin}`;
};

export const dayPartsStateToData = ({ dayParts, is15MinuteBinsModeActive, analysisTypeCode }) => {
    const hasAllDayPart = dayParts.find(getIsAllDayPart);
    let _dayParts = dayParts;

    // Add "All Day" part (if it is not already added) since we don't show it in the UI.
    if (is15MinuteBinsModeActive && !hasAllDayPart && !getIsTMCAnalysis(analysisTypeCode)) {
        _dayParts = [DAY_PARTS.ALL_DAY, ..._dayParts];
    }

    return _dayParts
        .filter(part => part.name && part.start?.name && part.end?.name)
        .map(part => {
            const start = moment(getHourCode(part.start.name), "HHmm");
            const end = moment(getHourCode(part.end.name), "HHmm");

            if (!is15MinuteBinsModeActive || getIsAllDayPart(part)) {
                // End bin should be 1 minute less.
                const truncatedEndBin = end.subtract(1, "minutes").format("HHmm");

                return `${part.name}|8${start.format("HHmm")}${truncatedEndBin}`;
            }

            const ranges = [];
            let i = 1;

            // A user select 24h bin in which start and end bins are the same. Manually add first bin.
            if (start.format("HHmm") === end.format("HHmm")) {
                const range = generateFifteenMinuteBin({ dayPart: part, start, index: i });

                ranges.push(range);

                start.add(FIFTEEN_MINUTES_BIN_GRANULARITY, "minutes");
                i++;
            }

            while (start.format("HHmm") !== end.format("HHmm")) {
                const range = generateFifteenMinuteBin({ dayPart: part, start, index: i });

                ranges.push(range);

                start.add(FIFTEEN_MINUTES_BIN_GRANULARITY, "minutes");
                i++;
            }

            return ranges.join(",");
        })
        .join(",");
};

export const getPartialMonthsRange = partialPeriods => {
    if (!partialPeriods.length) return null;

    return {
        startDate: partialPeriods[0].startDate,
        endDate: moment(partialPeriods[partialPeriods.length - 1].endDate)
            .endOf("month")
            .valueOf(),
    };
};

// When org has Specific Dates disabled and partial data month selected, prepare Excluded dates for save
export const prepareExcludedDateRanges = (partialDataPeriods, analysisDataMonths) => {
    const excludedPartialPeriods = [];
    const dataMonths = analysisDataMonths
        .filter(dataMonth =>
            isPartialDataPeriod(partialDataPeriods, dataMonth.year, dataMonth.month),
        )
        .map(dataMonth => {
            const monthDayPart = dataMonth.display_name.split(" ")[0];
            const monthDays = monthDayPart.split("-");
            const firstDay = Number(monthDays[0]);
            const lastDay = Number(monthDays[1]);

            if (firstDay === 1) {
                excludedPartialPeriods.push({
                    startDate: moment([
                        dataMonth.year,
                        dataMonth.month - 1,
                        lastDay + 1,
                    ]).valueOf(),
                    endDate: moment([dataMonth.year, dataMonth.month - 1])
                        .endOf("month")
                        .valueOf(),
                });
            } else {
                excludedPartialPeriods.push({
                    startDate: moment([dataMonth.year, dataMonth.month - 1, 1]).valueOf(),
                    endDate: moment([dataMonth.year, dataMonth.month - 1, firstDay - 1])
                        .endOf("day")
                        .valueOf(),
                });
            }

            return {
                startDate: moment([dataMonth.year, dataMonth.month - 1, firstDay]),
                endDate: moment([dataMonth.year, dataMonth.month - 1, lastDay]),
            };
        });

    if (!dataMonths.length) return [];

    return excludedPartialPeriods.filter(partialPeriod => {
        return !dataMonths.some(
            dataMonth =>
                moment(dataMonth.startDate).isSame(partialPeriod.startDate, "day") &&
                moment(dataMonth.endDate).isSame(partialPeriod.endDate, "day"),
        );
    });
};

// Collects all excluded dates to an array.
export const makeExcludedDatesList = excludedDataPeriods => {
    return excludedDataPeriods.reduce((result, period) => {
        // Skip over data periods that are in the middle of adding.
        if (!period.startDate || !period.endDate) {
            return result;
        }
        const current = moment(period.startDate);
        const end = moment(period.endDate);

        const dates = [];

        while (!current.isAfter(end)) {
            dates.push(current.format("MM/DD/YYYY"));
            current.add(1, "days");
        }

        return [...result, ...dates];
    }, []);
};

export const getDataPeriodsDayCount = (dataPeriods, excludedDataPeriods) => {
    const excludedDatesList = makeExcludedDatesList(excludedDataPeriods);

    return dataPeriods.reduce((currentDayCount, period) => {
        // Skip over data periods that are in the middle of adding.
        if (!period.startDate || !period.endDate) {
            return currentDayCount;
        }

        const start = moment(period.startDate);
        const end = moment(period.endDate);

        let newCount = currentDayCount;
        const current = moment(start);

        // Iterate through each date in a period to count the days.
        while (!current.isAfter(end)) {
            const parsedDate = current.format("MM/DD/YYYY");

            if (!excludedDatesList.includes(parsedDate)) {
                newCount += 1;
            }

            current.add(1, "days");
        }

        return newCount;
    }, 0);
};

// Checks that all data periods are Census 2020 dates (from 2019 forward)
export const getIsOnlyCensus2020DataPeriods = ({ dataPeriodsType, dataPeriodsData }) => {
    if (dataPeriodsType === "dataPeriods") {
        return dataPeriodsData.every(dataPeriod => {
            const { startDate, endDate, editStartDate, editEndDate } = dataPeriod;

            const _startDate = editStartDate || startDate;
            const _endDate = editEndDate || endDate;

            if (!_startDate || !_endDate) return true;

            return (
                moment(_startDate, "x").get("year") >= CENSUS_2020_THRESHOLD_YEAR &&
                moment(_endDate, "x").get("year") >= CENSUS_2020_THRESHOLD_YEAR
            );
        });
    }

    return dataPeriodsData.every(monthData => monthData.year >= CENSUS_2020_THRESHOLD_YEAR);
};

// Checks that some data periods are Census 2020 dates (from 2019 forward)
export const getHasCensus2020DataPeriods = ({ dataPeriodsType, dataPeriodsData }) => {
    const isOnlyCensus2020PeriodSelected = getIsOnlyCensus2020DataPeriods({
        dataPeriodsType,
        dataPeriodsData,
    });

    if (isOnlyCensus2020PeriodSelected) return true;
    if (dataPeriodsType === "dataPeriods") {
        return dataPeriodsData.some(dataPeriod => {
            for (const key in dataPeriod) {
                const date = moment(dataPeriod[key], "x");

                if (date.get("year") >= CENSUS_2020_THRESHOLD_YEAR) return true;
            }

            return false;
        });
    }

    return dataPeriodsData.some(monthData => monthData.year >= CENSUS_2020_THRESHOLD_YEAR);
};

// Checks that analysis has mixed data periods: Census 2010 dates (2019 backward) and Census 2020 dates (from 2019 forward)
export const getIsDataPeriodsMixedWithCensus2020 = ({ dataPeriodsType, dataPeriodsData }) => {
    const isOnlyCensus2020PeriodSelected = getIsOnlyCensus2020DataPeriods({
        dataPeriodsType,
        dataPeriodsData,
    });

    if (dataPeriodsType === "dataPeriods") {
        return isOnlyCensus2020PeriodSelected
            ? false
            : dataPeriodsData.some(dataPeriod => {
                  const [startDateKey, endDateKey] =
                      dataPeriod.status === "editing" || dataPeriod.status === "adding"
                          ? ["editStartDate", "editEndDate"]
                          : ["startDate", "endDate"];

                  return [startDateKey, endDateKey].some(dateKey => {
                      const date = moment(dataPeriod[dateKey], "x");

                      return date.get("year") >= CENSUS_2020_THRESHOLD_YEAR;
                  });
              });
    }

    return isOnlyCensus2020PeriodSelected
        ? false
        : dataPeriodsData.some(monthData => monthData.year >= CENSUS_2020_THRESHOLD_YEAR);
};

// Checks that Truck Volume analysis has valid data periods (01/01/2019 - 31/12/2021)
export const getIsOnlyTruckVolumeDataPeriods = ({ dataPeriodsType, dataPeriodsData }) => {
    if (dataPeriodsData.length === 0) {
        return false;
    }
    if (dataPeriodsType === "dataPeriods") {
        return dataPeriodsData.every(dataPeriod => {
            const datesKeys =
                dataPeriod.status === "editing" || dataPeriod.status === "adding"
                    ? ["editStartDate", "editEndDate"]
                    : ["startDate", "endDate"];
            return datesKeys.every(dateKey => {
                const dateValue = dataPeriod[dateKey];

                if (!dateValue) return true;

                const date = moment(dateValue, "x");
                return TRUCK_VOLUME_YEARS.includes(date.get("year"));
            });
        });
    }

    return dataPeriodsData.every(monthData => TRUCK_VOLUME_YEARS.includes(monthData.year));
};

// Checks that Truck Index analysis doesn't support Medium Duty (10/01/2022 - on the present)
export const hasExcludedMediumDutyDataPeriods = dataPeriodsData => {
    const date = new Date("10/01/2022").getTime();

    return dataPeriodsData.some(dataPeriod => dataPeriod.endDate >= date);
};

// Checks that analysis contains Aug'2021 data period
export const getHasAug2021DataPeriod = ({ dataPeriodsType, dataPeriodsData }) => {
    if (dataPeriodsData.length === 0) {
        return false;
    }

    if (dataPeriodsType === "dataPeriods") {
        return dataPeriodsData.some(dataPeriod => {
            if (moment("2021-08-01").isBetween(dataPeriod.startDate, dataPeriod.endDate)) {
                return true;
            }
            for (const key in dataPeriod) {
                const date = moment(dataPeriod[key], "x");

                if (date.get("year") === 2021 && date.get("month") === 7) return true;
            }

            return false;
        });
    }

    return dataPeriodsData.some(monthData => monthData.year === 2021 && monthData.month === 8);
};

// Checks that analysis contains upsampled metrics dates (CVD_UPSAMPLED_METRICS_DATES)
export const getHasCVDUpsampledMetricsDates = ({ dataPeriodsType, dataPeriodsData }) => {
    if (!dataPeriodsData.length) {
        return false;
    } else if (dataPeriodsType === "dataPeriods") {
        return dataPeriodsData.some(dataPeriod => {
            return CVD_UPSAMPLED_METRICS_DATES.find(
                upsampledDateRange =>
                    (moment(dataPeriod.startDate).isBefore(upsampledDateRange.startDate, "day") &&
                        moment(dataPeriod.endDate).isAfter(upsampledDateRange.endDate, "day")) ||
                    moment(dataPeriod.startDate).isBetween(
                        upsampledDateRange.startDate,
                        upsampledDateRange.endDate,
                        "day",
                        "[]",
                    ) ||
                    moment(dataPeriod.endDate).isBetween(
                        upsampledDateRange.startDate,
                        upsampledDateRange.endDate,
                        "day",
                        "[]",
                    ),
            );
        });
    }

    return dataPeriodsData.some(monthData => {
        return CVD_UPSAMPLED_METRICS_DATES.find(upsampledDateRange => {
            const date = moment(upsampledDateRange.startDate);
            return date.year() === monthData.year && date.month() + 1 === monthData.month;
        });
    });
};

export const getHasBikePedAnalysisInvalidDataPeriods = analysis => {
    // Check if draft or analysis was created with date range
    if (!analysis.data_periods) return true;

    return analysis.data_periods.some(
        monthData => monthData.year_num < BIKE_PED_MIN_YEAR_WITH_DATA,
    );
};

export const getHasInvalidTTApiTimePeriods = (analysis, availableDataMonths) => {
    if (analysis.project_type !== CREATE_ANALYSIS_TYPES.NETWORK_OD.code) return false;

    const availableTTApiMonths = availableDataMonths[MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM_API.code];
    const ttApiProperty =
        analysis.output_type_id === CALIBRATIONS.VOLUME.id
            ? "has_tt_api_volume"
            : "has_tt_api_metric";
    if (analysis.date_format === "data_periods") {
        return analysis.data_periods.some(dataPeriod => {
            const month = availableTTApiMonths.find(
                availableMonth =>
                    availableMonth.year === dataPeriod.year_num &&
                    availableMonth.month === dataPeriod.month_num,
            );
            return !month || !month[ttApiProperty];
        });
    }
    return analysis.date_ranges.some(dateRange => {
        const curMonth = moment(dateRange.start_date).month();
        const curYear = moment(dateRange.start_date).year();
        const month = availableTTApiMonths.find(
            availableMonth =>
                availableMonth.year === curYear && availableMonth.month === curMonth + 1,
        );
        return !month || !month[ttApiProperty];
    });
};

export const getHasInvalidGMCVDTimePeriods = (
    analysis,
    availableDataMonths,
    gmCVDFeatureState,
) => {
    const { supported: featureSupported, enabled: featureEnabled } =
        gmCVDFeatureState[analysis.project_type] || {};
    if (
        analysis.travel_mode_type !== MODES_OF_TRAVEL.ALL_VEHICLES_CVD.code ||
        !featureSupported ||
        featureEnabled
    ) {
        return { isInvalid: false };
    }

    const [calibrationCode, metricProperty] =
        analysis.output_type_id === CALIBRATIONS.VOLUME.id
            ? [CALIBRATIONS.VOLUME.code, "has_cvd_volume"]
            : [CALIBRATIONS.INDEX.code, "has_cvd_metric"];
    const defaultDataMonths = availableDataMonths[MODES_OF_TRAVEL.ALL_VEHICLES_CVD.code].filter(
        dataPeriod => {
            // GM CVD months should be included only if appropriate feature flag is enabled
            return dataPeriod.has_gm_metric
                ? featureEnabled && dataPeriod.is_default_cvd
                : dataPeriod.is_default_cvd && dataPeriod.has_cvd_volume;
        },
    );
    const defaultDataPeriods = collapseMonthsToRanges(defaultDataMonths, []);
    const availableCVDMonths = availableDataMonths[MODES_OF_TRAVEL.ALL_VEHICLES_CVD.code].filter(
        dataPeriod =>
            enabledForTravelModeAndCalibrationCombination({
                dataPeriod,
                travelModeCode: MODES_OF_TRAVEL.ALL_VEHICLES_CVD.code,
                calibrationCode,
                isGMCVDEnabled: featureEnabled,
            }),
    );

    let isInvalid;
    if (analysis.date_format === "date_ranges") {
        isInvalid = analysis.date_ranges.some(dateRange => {
            const curMonth = moment(dateRange.start_date).month();
            const curYear = moment(dateRange.start_date).year();
            const month = availableCVDMonths.find(
                availableMonth =>
                    availableMonth.year === curYear && availableMonth.month === curMonth + 1,
            );
            return !month || !month[metricProperty];
        });
    } else {
        isInvalid = analysis.data_periods.some(dataMonth => {
            const month = availableCVDMonths.find(
                availableMonth =>
                    availableMonth.year === dataMonth.year_num &&
                    availableMonth.month === dataMonth.month_num,
            );
            return !month || !month[metricProperty];
        });
    }

    return { isInvalid, defaultDataMonths, defaultDataPeriods };
};

export const getTimePeriodsSwitchPattern = ({
    isAvailableSpecificProjectDates,
    currentTravelMode,
    newTravelMode,
}) => {
    const isCurrentTravelModeAllVehiclesOrTruck =
        getIsAllVehiclesOrTruckTravelMode(currentTravelMode);
    const isNewTravelModeAllVehiclesOrTruck = getIsAllVehiclesOrTruckTravelMode(newTravelMode);

    if (
        !isAvailableSpecificProjectDates ||
        (!isCurrentTravelModeAllVehiclesOrTruck && !isNewTravelModeAllVehiclesOrTruck)
    ) {
        return TIME_PERIODS_SWITCH_PATTERNS_ON_TRAVEL_MODE_CHANGE.MONTHS_MONTHS;
    }

    if (isCurrentTravelModeAllVehiclesOrTruck && isNewTravelModeAllVehiclesOrTruck) {
        return TIME_PERIODS_SWITCH_PATTERNS_ON_TRAVEL_MODE_CHANGE.PERIODS_PERIODS;
    }

    if (isCurrentTravelModeAllVehiclesOrTruck && !isNewTravelModeAllVehiclesOrTruck) {
        return TIME_PERIODS_SWITCH_PATTERNS_ON_TRAVEL_MODE_CHANGE.PERIODS_MONTHS;
    }

    return TIME_PERIODS_SWITCH_PATTERNS_ON_TRAVEL_MODE_CHANGE.MONTHS_PERIODS;
};

export const isMonthInARow = (currentMonth, prevMonth) => {
    const isTheSameYear = currentMonth.year === prevMonth.year;
    const isYearInARow = currentMonth.year - prevMonth.year === 1;

    if (isTheSameYear) {
        return currentMonth.month - prevMonth.month === 1;
    } else if (isYearInARow) {
        return currentMonth.month === 1 && prevMonth.month === 12;
    } else {
        return false;
    }
};

// Return array of periods. Each period consists of one month or the first and the last months.
// Period has two elements if period is more than one month.
export const getGroupedDataMonths = dataMonths => {
    const groupedDataMonths = [];
    let period = [];

    const sortMonthsData = (a, b) => {
        if (a.year > b.year) return 1;
        if (a.year === b.year) {
            if (a.month > b.month) return 1;
            else return -1;
        }
        if (a.year < b.year) return -1;
        return 0;
    };

    const sortedDataMonths = [...dataMonths].sort(sortMonthsData);

    const filteredDataMonths = sortedDataMonths.reduce((result, monthData) => {
        const isMonthAdded = result.some(
            _month => _month.month === monthData.month && _month.year === monthData.year,
        );
        return isMonthAdded ? result : [...result, monthData];
    }, []);

    filteredDataMonths.forEach((currentMonth, index, array) => {
        const isLastMonth = array.length - 1 === index;
        const prevMonth = index ? array[index - 1] : null;

        if (!prevMonth) {
            if (isLastMonth) {
                groupedDataMonths.push([currentMonth]);
            } else {
                period.push(currentMonth);
            }
            return;
        }

        if (isMonthInARow(currentMonth, prevMonth) && !isLastMonth) {
            period.push(currentMonth);
        } else if (!isMonthInARow(currentMonth, prevMonth) && !isLastMonth) {
            groupedDataMonths.push(period);
            period = [currentMonth];
        } else if (isMonthInARow(currentMonth, prevMonth) && isLastMonth) {
            period.push(currentMonth);
            groupedDataMonths.push(period);
        } else {
            groupedDataMonths.push(period, [currentMonth]);
        }
    });

    return groupedDataMonths.reduce((result, _period) => {
        if (_period.length === 1) {
            return [...result, _period];
        }

        const updatedPeriod = [_period[0], _period[_period.length - 1]];
        return [...result, updatedPeriod];
    }, []);
};

const createDate = ({ dataPeriod, isStart }) =>
    isStart
        ? moment([dataPeriod.year, dataPeriod.month - 1]).valueOf()
        : moment([dataPeriod.year, dataPeriod.month - 1])
              .endOf("month")
              .valueOf();

export const getDataPeriodsFromMonths = groupedDataMonths =>
    groupedDataMonths.map(period => {
        const endDataPeriod = period.length === 1 ? period[0] : period[1];

        return {
            startDate: createDate({ dataPeriod: period[0], isStart: true }),
            endDate: createDate({ dataPeriod: endDataPeriod, isStart: false }),
        };
    });

export const getIsAllSelectedMonthsAvailable = ({ availableDataMonths, selectedDataMonths }) =>
    selectedDataMonths.every(
        selectedDataMonth =>
            !!availableDataMonths.find(
                dataMonth => dataMonth.display_name === selectedDataMonth.display_name,
            ),
    );

const isPartialMonth = month => month.display_name.split(" ").length > 1;

export const getSelectedMonthsDataWithPartialMonths = ({
    availableDataMonths,
    selectedDataMonths,
}) => {
    const _availableDataMonths = availableDataMonths.map(dataMonth => {
        const displayNameParts = dataMonth.display_name.split(" ");
        const display_name = displayNameParts[displayNameParts.length - 1];
        return {
            ...dataMonth,
            display_name,
        };
    });

    const isAllDataMonthsAvailable = getIsAllSelectedMonthsAvailable({
        availableDataMonths: _availableDataMonths,
        selectedDataMonths,
    });

    if (!isAllDataMonthsAvailable) return [];

    return availableDataMonths.filter(dataMonth => {
        const isAvailable = selectedDataMonths.find(
            _dataMonth =>
                !!(
                    _dataMonth.display_name === dataMonth.display_name ||
                    (dataMonth.year === _dataMonth.year && dataMonth.month === _dataMonth.month)
                ),
        );

        return !!isAvailable;
    });
};

export const convertPartialMonthsToCommonMonths = months => {
    const updatedMonths = months.map(selectedMonth =>
        isPartialMonth(selectedMonth)
            ? { ...selectedMonth, display_name: selectedMonth.display_name.split(" ")[1] }
            : selectedMonth,
    );

    return uniqBy(updatedMonths, "display_name");
};

export const hasPartialMonths = months => {
    return !!months.find((selectedMonth, i, _months) => {
        if (isPartialMonth(selectedMonth)) {
            const displayName = selectedMonth.display_name.split(" ")[1];
            const monthsCount = _months.filter(month => month.display_name.includes(displayName));

            return monthsCount.length === 1;
        } else {
            return false;
        }
    });
};

export const isAllPeriodsContainFullMonths = periods =>
    !periods.some(period => {
        const startDate = moment(period.startDate).format("D");
        const endDate = moment(period.endDate).format("YYYY-MM-DD");
        const [year, month, periodEndDay] = endDate.split("-");
        const endDateYearMonth = `${year}-${month}`;
        const daysInMonth = moment(endDateYearMonth, "YYYY-MM").daysInMonth().toString();

        return startDate !== "1" || periodEndDay !== daysInMonth;
    });

export const getMonthsFromPeriod = period => {
    const lastMonthIndexInYear = 12;
    const firstMonthIndexInYear = 1;

    const years = [];

    for (let i = period.startDate.year; i <= period.endDate.year; i++) {
        years.push(i);
    }

    return years.reduce((result, year) => {
        const isPeriodFinishInCurrentYear = year === period.endDate.year;
        const isPeriodStartInCurrentYear = year === period.startDate.year;

        const months = [];

        if (isPeriodStartInCurrentYear && isPeriodFinishInCurrentYear) {
            for (let i = period.startDate.month; i <= period.endDate.month; i++) {
                months.push({ month: i, year });
            }
        }

        if (!isPeriodStartInCurrentYear && !isPeriodFinishInCurrentYear) {
            for (let i = firstMonthIndexInYear; i <= lastMonthIndexInYear; i++) {
                months.push({ month: i, year });
            }
        }

        if (isPeriodStartInCurrentYear && !isPeriodFinishInCurrentYear) {
            for (let i = period.startDate.month; i <= lastMonthIndexInYear; i++) {
                months.push({ month: i, year });
            }
        }

        if (!isPeriodStartInCurrentYear && isPeriodFinishInCurrentYear) {
            for (let i = firstMonthIndexInYear; i <= period.endDate.month; i++) {
                months.push({ month: i, year });
            }
        }

        return [...result, ...months];
    }, []);
};

export const convertDataPeriodsToMonths = periods => {
    const periodsWithMonthsAndYears = periods.map(period => {
        const startDate = moment(period.startDate).format("YYYY-MM");
        const endDate = moment(period.endDate).format("YYYY-MM");

        const [startDateYear, startDateMonth] = startDate.split("-");
        const [endDateYear, endDateMonth] = endDate.split("-");

        return {
            startDate: { year: Number(startDateYear), month: Number(startDateMonth) },
            endDate: { year: Number(endDateYear), month: Number(endDateMonth) },
        };
    });

    return periodsWithMonthsAndYears.reduce((result, period) => {
        const monthsFromPeriod = getMonthsFromPeriod(period);

        return [...result, ...monthsFromPeriod];
    }, []);
};

const isDateWithinRanges = (date, whiteListDateRanges) => {
    if (!whiteListDateRanges.length) return false;

    return whiteListDateRanges.every(
        dateRange => date >= dateRange.startDate && date <= dateRange.endDate,
    );
};

export const getIsAllSelectedPeriodRangesSupported = ({
    whiteListDateRanges,
    selectedTimePeriods,
}) =>
    !selectedTimePeriods.some(
        period =>
            !isDateWithinRanges(period.startDate, whiteListDateRanges) ||
            !isDateWithinRanges(period.endDate, whiteListDateRanges),
    );

// This pattern is only for truck and all_vehicles travel mode
const getTimePeriodsDataForPeriodsToPeriods = ({
    newTravelMode,
    timePeriodsData,
    whiteListDateRanges,
}) => {
    const whiteListDateRangesByMode = whiteListDateRanges[newTravelMode];

    const isAllPeriodsSupported = getIsAllSelectedPeriodRangesSupported({
        whiteListDateRanges: whiteListDateRangesByMode,
        selectedTimePeriods: timePeriodsData.dataPeriods,
    });

    if (!isAllPeriodsSupported) {
        return { hasUnsupportedTimePeriods: true, updatedTimePeriods: null };
    }

    if (newTravelMode === MODES_OF_TRAVEL.TRUCK.code) {
        return { hasUnsupportedTimePeriods: false, updatedTimePeriods: null };
    }

    return {
        hasUnsupportedTimePeriods: !!getIsDataPeriodsMixedWithCensus2020({
            dataPeriodsType: "dataPeriods",
            dataPeriodsData: timePeriodsData.dataPeriods,
        }),
        updatedTimePeriods: null,
    };
};

const getTimePeriodsDataForMonthsToPeriods = ({
    timePeriodsData,
    newTravelMode,
    availableDataMonths,
}) => {
    const availableDataMonthsForMode = availableDataMonths[newTravelMode];

    const isAllSelectedMonthsAvailable = getIsAllSelectedMonthsAvailable({
        availableDataMonths: availableDataMonthsForMode,
        selectedDataMonths: timePeriodsData.dataMonths,
    });

    if (!isAllSelectedMonthsAvailable) {
        return {
            hasUnsupportedTimePeriods: true,
            updatedTimePeriods: null,
        };
    }

    const groupedDataMonths = getGroupedDataMonths(timePeriodsData.dataMonths);

    const dataPeriodsFromMonths = getDataPeriodsFromMonths(groupedDataMonths);

    return {
        hasUnsupportedTimePeriods: false,
        updatedTimePeriods: { dataPeriods: dataPeriodsFromMonths },
    };
};

const getTimePeriodsDataForMonthsToMonths = ({
    timePeriodsData,
    newTravelMode,
    availableDataMonths,
}) => {
    const availableDataMonthsForMode = availableDataMonths[newTravelMode];

    const hasAvailableDataWithPartialMonths = !!availableDataMonthsForMode.find(dataMonth =>
        isPartialMonth(dataMonth),
    );

    const hasSelectedDataWithPartialMonths = !!timePeriodsData.dataMonths.find(dataMonth =>
        isPartialMonth(dataMonth),
    );

    // Switch from any travel mode to all_vehicles
    if (hasAvailableDataWithPartialMonths && !hasSelectedDataWithPartialMonths) {
        const isDataPeriodsMixedWithCensus2020 = !!getIsDataPeriodsMixedWithCensus2020({
            dataPeriodsType: "dataMonths",
            dataPeriodsData: timePeriodsData.dataMonths,
        });

        if (isDataPeriodsMixedWithCensus2020) {
            return {
                hasUnsupportedTimePeriods: true,
                updatedTimePeriods: null,
            };
        }

        const updatedSelectedDataMonths = getSelectedMonthsDataWithPartialMonths({
            availableDataMonths: availableDataMonthsForMode,
            selectedDataMonths: timePeriodsData.dataMonths,
        });

        const hasUnsupportedTimePeriods = !updatedSelectedDataMonths.length;

        return {
            hasUnsupportedTimePeriods,
            updatedTimePeriods: hasUnsupportedTimePeriods
                ? null
                : { dataMonths: updatedSelectedDataMonths },
        };
    }

    // Switch all_vehicles to any other travel mode
    if (!hasAvailableDataWithPartialMonths && hasSelectedDataWithPartialMonths) {
        const hasPartialMonth = hasPartialMonths(timePeriodsData.dataMonths);

        const updatedSelectedData = convertPartialMonthsToCommonMonths(timePeriodsData.dataMonths);

        const hasUnsupportedTimePeriods =
            hasPartialMonth ||
            !getIsAllSelectedMonthsAvailable({
                availableDataMonths: availableDataMonthsForMode,
                selectedDataMonths: updatedSelectedData,
            });

        return {
            hasUnsupportedTimePeriods,
            updatedTimePeriods: hasUnsupportedTimePeriods
                ? null
                : { dataMonths: updatedSelectedData },
        };
    }

    // Switch between all travel modes except all_vehicles (all_vehicles has partial data months)
    return {
        hasUnsupportedTimePeriods: !getIsAllSelectedMonthsAvailable({
            availableDataMonths: availableDataMonthsForMode,
            selectedDataMonths: timePeriodsData.dataMonths,
        }),
        updatedTimePeriods: null,
    };
};

const getTimePeriodsDataForPeriodsToMonths = ({
    newTravelMode,
    timePeriodsData,
    availableDataMonths,
}) => {
    const availableDataMonthsForMode = availableDataMonths[newTravelMode];
    const isPeriodsValid = isAllPeriodsContainFullMonths(timePeriodsData.dataPeriods);

    if (isPeriodsValid) {
        const selectedMonths = convertDataPeriodsToMonths(timePeriodsData.dataPeriods);
        let isAllMonthsAvailable = true;

        const updatedMonths = selectedMonths.map(({ month: currentMonth, year: currentYear }) => {
            const updatedMonth = availableDataMonthsForMode.find(
                _month => _month.month === currentMonth && _month.year === currentYear,
            );

            if (updatedMonth) {
                return updatedMonth;
            } else {
                isAllMonthsAvailable = false;
                return {};
            }
        });

        return {
            hasUnsupportedTimePeriods: !isAllMonthsAvailable,
            updatedTimePeriods: isAllMonthsAvailable ? { dataMonths: updatedMonths } : null,
        };
    }

    return {
        hasUnsupportedTimePeriods: true,
        updatedTimePeriods: null,
    };
};

const TIME_PERIODS_SWITCH_PATTERNS_ON_TRAVEL_MODE_CHANGE_HANDLERS = {
    [TIME_PERIODS_SWITCH_PATTERNS_ON_TRAVEL_MODE_CHANGE.PERIODS_PERIODS.id]:
        getTimePeriodsDataForPeriodsToPeriods,
    [TIME_PERIODS_SWITCH_PATTERNS_ON_TRAVEL_MODE_CHANGE.PERIODS_MONTHS.id]:
        getTimePeriodsDataForPeriodsToMonths,
    [TIME_PERIODS_SWITCH_PATTERNS_ON_TRAVEL_MODE_CHANGE.MONTHS_MONTHS.id]:
        getTimePeriodsDataForMonthsToMonths,
    [TIME_PERIODS_SWITCH_PATTERNS_ON_TRAVEL_MODE_CHANGE.MONTHS_PERIODS.id]:
        getTimePeriodsDataForMonthsToPeriods,
};

export const getHasUnsupportedTimePeriods = ({
    newTravelMode,
    timePeriodsData,
    currentTravelMode,
    isAvailableSpecificProjectDates,
    availableDataMonths,
    whiteListDateRanges,
}) => {
    const switchPattern = getTimePeriodsSwitchPattern({
        newTravelMode,
        currentTravelMode,
        isAvailableSpecificProjectDates,
    });

    // Handle empty selection
    if (!timePeriodsData[switchPattern.from].length) {
        return {
            hasUnsupportedTimePeriods: false,
            updatedTimePeriods: { [switchPattern.to]: [] },
        };
    }
    if (
        [
            MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM.code,
            MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM_API.code,
        ].includes(newTravelMode)
    ) {
        return {
            hasUnsupportedTimePeriods: false,
            updatedTimePeriods: timePeriodsData,
        };
    }

    const patternHandler =
        TIME_PERIODS_SWITCH_PATTERNS_ON_TRAVEL_MODE_CHANGE_HANDLERS[switchPattern.id];

    return patternHandler({
        timePeriodsData,
        availableDataMonths,
        newTravelMode,
        whiteListDateRanges,
    });
};

export const getUnsupportedRangesFromDataPeriods = ({
    availableDataMonths,
    selectedDataPeriods,
    newTravelModeValue,
}) => {
    const isDataPeriodsMixedWithCensus2020 = getIsDataPeriodsMixedWithCensus2020({
        dataPeriodsType: "dataPeriods",
        dataPeriodsData: selectedDataPeriods,
    });

    const selectedMonths = convertDataPeriodsToMonths(selectedDataPeriods);

    if (isDataPeriodsMixedWithCensus2020 && getIsAllVehiclesTravelMode(newTravelModeValue)) {
        return getGroupedDataMonths(selectedMonths);
    }

    const unavailableMonths = selectedMonths.filter(
        selectedMonthData =>
            !availableDataMonths.some(
                monthData =>
                    monthData.month === selectedMonthData.month &&
                    monthData.year === selectedMonthData.year,
            ),
    );

    return getGroupedDataMonths(unavailableMonths);
};

export const getIsPartOfMonthSelected = ({ monthData, selectedMonths }) => {
    if (!isPartialMonth(monthData)) return false;

    const displayName = monthData.display_name.split(" ")[1];
    const monthsCount = selectedMonths.filter(month => month.display_name.includes(displayName));

    return monthsCount.length === 1;
};

export const getUnsupportedRangesFromDataMonths = ({
    availableDataMonths,
    selectedDataMonths,
    newTravelModeValue,
}) => {
    const isDataPeriodsMixedWithCensus2020 = getIsDataPeriodsMixedWithCensus2020({
        dataPeriodsType: "dataMonths",
        dataPeriodsData: selectedDataMonths,
    });

    if (isDataPeriodsMixedWithCensus2020 && getIsAllVehiclesTravelMode(newTravelModeValue)) {
        return getGroupedDataMonths(selectedDataMonths);
    }

    const unavailableMonths = selectedDataMonths.filter((selectedMonthData, _, _months) => {
        const isPartOfMonthSelected = getIsPartOfMonthSelected({
            monthData: selectedMonthData,
            selectedMonths: _months,
        });

        if (isPartOfMonthSelected) return true;

        return !availableDataMonths.some(
            monthData =>
                monthData.month === selectedMonthData.month &&
                monthData.year === selectedMonthData.year,
        );
    });

    return getGroupedDataMonths(unavailableMonths);
};

export const getMonthLabel = monthDisplayName => {
    const separateDateAndMonthYear = monthDisplayName.split(" ");
    const monthAndYearData = separateDateAndMonthYear[separateDateAndMonthYear.length - 1];
    const [month, year] = monthAndYearData.split("'");
    return `${month} 20${year}`;
};

export const getResetLabel = unsupportedRanges => {
    const monthToLabel = month => moment(month).format("MMM YYYY");

    return unsupportedRanges
        .map(range => {
            if (range.length === 1) {
                return monthToLabel({
                    year: range[0].year,
                    month: range[0].month - 1,
                });
            } else {
                const [startMonth, endMonth] = range;
                return `${monthToLabel({
                    year: startMonth.year,
                    month: startMonth.month - 1,
                })} - ${monthToLabel({
                    year: endMonth.year,
                    month: endMonth.month - 1,
                })}`;
            }
        })
        .join(", ");
};

const getAvailableDataMonths = (
    newTravelModeValue,
    availableBusRailTruckDataMonths,
    availableDataMonthsByMode,
) => {
    const hasAvailableDataMonthsByMode = !!availableDataMonthsByMode[newTravelModeValue].length;

    if (!hasAvailableDataMonthsByMode) {
        return availableBusRailTruckDataMonths[newTravelModeValue] || [];
    }

    return availableDataMonthsByMode[newTravelModeValue];
};

const getResetInfoForCustomSelection = (customDateRanges, customDayTypes) => {
    if (customDayTypes.length) return "Custom Day Types";

    if (customDateRanges.length) return "Custom Data Period";

    return null;
};

export const getTimePeriodsResetInfo = ({
    newTravelModeValue,
    currentTravelMode,
    isAvailableSpecificProjectDates,
    dataMonths,
    availableDataMonthsByMode,
    dataPeriods,
    availableBusRailTruckDataMonths,
    analysisTypeCode,
    aadtYear,
    customDateRanges,
    customDayTypes,
}) => {
    // NP analyses don't support custom periods with "Truck_GT" mode
    if (
        getIsNetworkPerformanceAnalysis(analysisTypeCode) &&
        newTravelModeValue === MODES_OF_TRAVEL.TRUCK_GT.code &&
        (customDateRanges.length || customDayTypes.length)
    ) {
        return getResetInfoForCustomSelection(customDateRanges, customDayTypes);
    }

    const switchPattern = getTimePeriodsSwitchPattern({
        newTravelMode: newTravelModeValue,
        currentTravelMode,
        isAvailableSpecificProjectDates,
    });

    const availableDataMonths = getAvailableDataMonths(
        newTravelModeValue,
        availableBusRailTruckDataMonths,
        availableDataMonthsByMode,
    );

    const getUnsupportedRanges =
        switchPattern.from === "dataPeriods"
            ? getUnsupportedRangesFromDataPeriods
            : getUnsupportedRangesFromDataMonths;

    const unsupportedRanges = getUnsupportedRanges({
        availableDataMonths,
        selectedDataPeriods: dataPeriods,
        selectedDataMonths: dataMonths,
        newTravelModeValue,
    });

    const resetInfoByAnalysisType = {
        [CREATE_ANALYSIS_TYPES.AADT.code]: aadtYear,
    };

    if (!unsupportedRanges.length) {
        return resetInfoByAnalysisType[analysisTypeCode] ?? "Individual days";
    }

    return getResetLabel(unsupportedRanges);
};

export const getDayTypeKindByDayTypes = dayTypes => {
    const hasOnlyIndividualDays = dayTypes.every(dayType => {
        return dayType.name === DAY_TYPES.ALL_DAYS.name ? true : !!DAY_CODES[dayType.name];
    });
    return hasOnlyIndividualDays
        ? DAY_TYPES_KINDS.INDIVIDUAL_DAYS.id
        : DAY_TYPES_KINDS.DAY_RANGES.id;
};

export const getDayPartKindByDayParts = (dayParts, has15MinuteBins) => {
    const hasIndividualHours = dayParts.some(dayPart => !!getHourCode(dayPart.name));
    return hasIndividualHours || has15MinuteBins
        ? DAY_PARTS_KINDS.INDIVIDUAL_HOURS.id
        : DAY_PARTS_KINDS.DAY_PART_RANGES.id;
};

export const getWeekdayTypeById = id =>
    WEEKDAY_TYPES_LIST.find(weekdayTypeOption => weekdayTypeOption.id === id);

export const getWeekdayTypeByDayTypes = (dayTypes, preferredWeekdayType) => {
    const weekdayTypeFromPreferences =
        getWeekdayTypeById(preferredWeekdayType) || WEEKDAY_TYPES.MON_THU;
    const weekdayDayType = dayTypes.find(dayType => dayType.name === DAY_TYPES.WEEKDAY.name);

    if (!weekdayDayType) return weekdayTypeFromPreferences.id;

    const weekdayType = WEEKDAY_TYPES_LIST.find(
        weekdayTypeOption =>
            weekdayTypeOption.dayType.start.name === weekdayDayType.start.name &&
            weekdayTypeOption.dayType.end.name === weekdayDayType.end.name,
    );
    return weekdayType.id || weekdayTypeFromPreferences.id;
};

export const getDefaultDayTypes = (dayTypeKind, weekdayType) => {
    if (dayTypeKind === DAY_TYPES_KINDS.DAY_RANGES.id) {
        const _weekdayType = getWeekdayTypeById(weekdayType);
        const dayTypeForWeekday = _weekdayType
            ? _weekdayType.dayType
            : WEEKDAY_TYPES.MON_THU.dayType;

        return DAY_TYPES_KINDS.DAY_RANGES.defaultOptions.map(option => {
            if (option.name === DAY_TYPES.WEEKDAY.name) {
                return dayTypeForWeekday;
            }
            return option;
        });
    }

    return DAY_TYPES_KINDS.INDIVIDUAL_DAYS.defaultOptions;
};

export const getHasTimePeriodWithCVDLimitedTravelerAttrs = dataMonths => {
    if (!dataMonths) return false;

    return dataMonths.some(month => CVD_TRAVELER_ATTRIBUTES_REQUIRED_YEARS.includes(month.year));
};
