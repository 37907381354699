import type {
    IBaseField,
    IBin,
    IInput,
    IPercentileBin,
} from "@app/analysis/addOns/state/addOns.types";
import {
    DEFAULT_PRESET_GEOG_TYPE,
    ROUTE_OPTIONS,
    TPresetGeogType,
    TRouteOptions,
} from "@app/analysis/state/analysisConfiguration.constants";
import {
    DEFAULT_HWL_ZONE_INTERSECTION_TYPE,
    EMPTY_TRIP_BIN_ADD_INPUT,
    HWL_FILTER_OPTIONS,
    THWLFilterOption,
    THWLZoneIntersectionType,
    TRAVELER_PARAMS_US_CENSUS_2020,
    TRIP_BIN_TYPES,
} from "./addOns.constants";

export type TTripBinUiState = {
    mode: string;
    reason: string;
    enabled: boolean;
    addInput: IInput;
};

export type TTravelerBinUiState = {
    enabled: boolean;
};

export type TAdminSettings = {
    enable_viz: boolean;
    isMassiveQueue: boolean;
    enableUpsampling: boolean;
    bypassPrivacyCheck: boolean;
    enable_demographics: boolean;
    enableEmailNotification: boolean;
    bypassSizeValidationCheck: boolean;
    size_review_types: Array<string> | null;
    size_review_details: Array<string> | null;
};

export type THwlSettings = {
    enabled: boolean;
    hwlZoneIntersectionCode: THWLZoneIntersectionType["code"];
    hwlFilterOptions: {
        [key in THWLFilterOption["code"]]: boolean;
    };
};

export type TTripAttrSettings = {
    enabled: boolean;
    trip_speed_bins: Array<IBin>;
    trip_length_bins: Array<IBin>;
    trip_circuity_bins: Array<IBin>;
    trip_duration_bins: Array<IBin>;
    speed_percentile_bins: Array<IPercentileBin> | null;
    duration_percentile_bins: Array<IPercentileBin> | null;
    tripSpeed: TTripBinUiState;
    tripLength: TTripBinUiState;
    tripCircuity: TTripBinUiState;
    tripDuration: TTripBinUiState;
    speedPercentile: TTripBinUiState;
    travelTimePercentile: TTripBinUiState;
    networkPerformanceTripAttr: TTripBinUiState;
};

export type TTravelerAttrSettings = {
    enabled: boolean;
    tripPurposeBin: TTravelerBinUiState;
    educationIncomeBin: TTravelerBinUiState;
    equityDemographicsBin: TTravelerBinUiState;
    employmentCharacteristicsBin: TTravelerBinUiState;
    householdCharacteristicsBin: TTravelerBinUiState;
};

export type TCommercialVehicleSettings = {
    enabled: boolean;
    commercialVehicleType: {
        cvHeavy: boolean;
        cvMedium: boolean;
        cvLight: boolean;
    };
};

export type TValidationDetails = string | {};

export type TValidation = {
    isInvalid: boolean;
    fields: {
        segmentSpeed: IBaseField | null;
        routeOptions: IBaseField | null;
        tripSpeedBins: IBaseField | null;
        tripLengthBins: IBaseField | null;
        speedPercentile: IBaseField | null;
        segmentDuration: IBaseField | null;
        tripDurationBins: IBaseField | null;
        tripCircuityBins: IBaseField | null;
        travelTimePercentile: IBaseField | null;
    };
};

export type TAddOnsState = {
    validation: TValidation;
    hwlSettings: THwlSettings;
    routeOptions: TRouteOptions;
    adminSettings: TAdminSettings;
    tripAttrSettings: TTripAttrSettings;
    validationDetails: TValidationDetails;
    travelerAttrSettings: TTravelerAttrSettings;
    presetGeographyType: TPresetGeogType["code"];
    commercialVehicleSettings: TCommercialVehicleSettings;
};

export const INITIAL_TRIP_BIN_UI_STATE = {
    reason: "", // Invalid reason when editing.
    addInput: EMPTY_TRIP_BIN_ADD_INPUT, // Mode for adding or editing trip bins. Empty string means no action.
    mode: "",
    enabled: false,
};

export const INITIAL_TRAVELER_BIN_UI_STATE = {
    enabled: false,
};

export const ADD_ONS_INITIAL_STATE = {
    presetGeographyType: DEFAULT_PRESET_GEOG_TYPE.code,
    routeOptions: ROUTE_OPTIONS,
    hwlSettings: {
        enabled: false,
        hwlZoneIntersectionCode: DEFAULT_HWL_ZONE_INTERSECTION_TYPE.code,
        hwlFilterOptions: {
            hwl_enable_visitor: HWL_FILTER_OPTIONS.VISITOR.defaultValue,
            hwl_enable_resident: HWL_FILTER_OPTIONS.RESIDENT.defaultValue,
            hwl_enable_worker: HWL_FILTER_OPTIONS.WORKER.defaultValue,
        },
    },
    tripAttrSettings: {
        enabled: false,
        trip_duration_bins: [...TRIP_BIN_TYPES.DURATION.defaultValue],
        trip_length_bins: [...TRIP_BIN_TYPES.LENGTH.defaultValue],
        trip_speed_bins: [...TRIP_BIN_TYPES.SPEED.defaultValue],
        trip_circuity_bins: [...TRIP_BIN_TYPES.CIRCUITY.defaultValue],
        speed_percentile_bins: TRIP_BIN_TYPES.PERCENTILE.defaultValue,
        duration_percentile_bins: TRIP_BIN_TYPES.TRAVEL_TIME_PERCENTILE.defaultValue,
        tripDuration: INITIAL_TRIP_BIN_UI_STATE,
        tripSpeed: INITIAL_TRIP_BIN_UI_STATE,
        tripLength: INITIAL_TRIP_BIN_UI_STATE,
        tripCircuity: INITIAL_TRIP_BIN_UI_STATE,
        speedPercentile: INITIAL_TRIP_BIN_UI_STATE,
        travelTimePercentile: INITIAL_TRIP_BIN_UI_STATE,
        networkPerformanceTripAttr: INITIAL_TRIP_BIN_UI_STATE,
    },
    travelerAttrSettings: {
        enabled: false,
        [TRAVELER_PARAMS_US_CENSUS_2020.EQUITY_DEMOGRAPHICS.uiStateName]:
            INITIAL_TRAVELER_BIN_UI_STATE,
        [TRAVELER_PARAMS_US_CENSUS_2020.EDUCATION_INCOME.uiStateName]:
            INITIAL_TRAVELER_BIN_UI_STATE,
        [TRAVELER_PARAMS_US_CENSUS_2020.EMPLOYMENT_CHARACTERISTICS.uiStateName]:
            INITIAL_TRAVELER_BIN_UI_STATE,
        [TRAVELER_PARAMS_US_CENSUS_2020.HOUSEHOLD_CHARACTERISTICS.uiStateName]:
            INITIAL_TRAVELER_BIN_UI_STATE,
        [TRAVELER_PARAMS_US_CENSUS_2020.TRIP_PURPOSES.uiStateName]: INITIAL_TRAVELER_BIN_UI_STATE,
    },
    commercialVehicleSettings: {
        enabled: false,
        commercialVehicleType: {
            cvHeavy: false,
            cvMedium: false,
            cvLight: false,
        },
    },
    adminSettings: {
        bypassPrivacyCheck: true,
        bypassSizeValidationCheck: false,
        enableUpsampling: true,
        enableEmailNotification: true,
        enable_demographics: false,
        enable_viz: true,
        isMassiveQueue: false,
        size_review_types: null,
        size_review_details: null,
    },
    validationDetails: {},
    validation: {
        fields: {
            routeOptions: null,
            tripDurationBins: null,
            tripLengthBins: null,
            tripSpeedBins: null,
            tripCircuityBins: null,
            segmentDuration: null,
            segmentSpeed: null,
            speedPercentile: null,
            travelTimePercentile: null,
        },
        isInvalid: false,
    },
} as TAddOnsState;
