import { setTravelerAttrEnabled } from "@app/analysis/addOns/state/addOns.actions";
import { setAADTCalibrationYear } from "@app/analysis/basics/state/basics.actions";
import {
    getAnalysisCountry,
    getBasics,
    getCalibrationSettings,
    getIsUSTruckVolumeAnalysis,
} from "@app/analysis/basics/state/basics.selectors";
import {
    CREATE_ANALYSIS_TYPES,
    DEFAULT_AADT_YEAR,
    DEFAULT_AADT_YEAR_CA,
    DEFAULT_ALL_VEHICLES_BY_WEIGHT_YEAR,
} from "@app/analysis/state/analysisConfiguration.constants";
import { getIsCVDAnalysisWithLimitedTravelerAttrs } from "@app/analysis/state/analysisConfiguration.helpers";
import {
    getAvailableDataMonthsForMode,
    getDefaultDataMonthsForMode,
    getKFactorDefaultDataMonths,
} from "@app/analysis/state/analysisConfiguration.selectors";
import {
    getAnalysisTypeCode,
    getCensusMixingPermitted,
    isAnalysisTypeOf,
} from "@app/analysis/state/general/general.selectors";
import { setDataPeriods } from "@app/analysis/timePeriods/components/dataPeriodParams/dataPeriodParams.actions";
import { TIME_PERIODS_ACTION_TYPES } from "@app/analysis/timePeriods/state/timePeriods.actionTypes";
import {
    getHasTimePeriodWithCVDLimitedTravelerAttrs,
    getIsDataPeriodsMixedWithCensus2020,
    getIsOnlyTruckVolumeDataPeriods,
} from "@app/analysis/timePeriods/state/timePeriods.helpers";
import {
    getAadtSettings,
    getDataPeriodSettings,
    getDayPartSettings,
    getDayTypeSettings,
    isDateRangeFormat,
} from "@app/analysis/timePeriods/state/timePeriods.selectors";
import { TIME_PERIODS_INITIAL_STATE } from "@app/analysis/timePeriods/state/timePeriods.state";
import { createValidateField } from "@app/analysis/validation";
import { getAvailableAADTYears } from "@app/store/currentUser/currentUser.selector";
import { MODES_OF_TRAVEL, ORG_COUNTRIES } from "@common/constants/analysis.constants";
import { getIsNetworkPerformanceAnalysis } from "@common/helpers/analysis";
import { merge } from "lodash-es";
import { batch } from "react-redux";

// Validates data months section.
export const validateDataMonthsSection = ({
    dataMonths,
    censusMixingPermitted,
    isTruckVolumeAnalysis,
    isNetworkPerformanceAnalysis,
}) => {
    let dataMonthsError = "";

    if (!dataMonths.length) {
        dataMonthsError =
            "No month of data is selected. Analysis must have at least one month of data";
    } else if (isNetworkPerformanceAnalysis && dataMonths.length > 36) {
        dataMonthsError =
            "You cannot analyze more than 36 months of data for this analysis type. Select fewer data months from the Time Periods tab.";
    } else if (!censusMixingPermitted) {
        const isMixedWithCensus2020 = getIsDataPeriodsMixedWithCensus2020({
            dataPeriodsData: dataMonths,
            dataPeriodsType: "dataMonths",
        });
        if (isMixedWithCensus2020) {
            dataMonthsError =
                "Selected data months combination is not supported. Please make sure all data months are before or after Jan 2019";
        }
    } else if (isTruckVolumeAnalysis) {
        const isTruckVolumeDataPeriods = getIsOnlyTruckVolumeDataPeriods({
            dataPeriodsData: dataMonths,
            dataPeriodsType: "dataMonths",
        });
        if (!isTruckVolumeDataPeriods) {
            dataMonthsError =
                "Selected data periods combination is not supported for StreetLight Truck Volume. Please make sure all data periods end before Jan 2022 and begin after Dec 2018";
        }
    }

    return createValidateField(dataMonthsError);
};

export const setUseCustomRanges = shouldUseCustomRanges => ({
    type: TIME_PERIODS_ACTION_TYPES.SET_USE_CUSTOM_RANGES,
    data: { shouldUseCustomRanges },
});

export const setTimePeriodsValidation = newValidation => ({
    type: TIME_PERIODS_ACTION_TYPES.SET_VALIDATION,
    data: { validation: newValidation },
});

export const setDataPeriodSettings = (propertyName, propertyValue) => ({
    type: TIME_PERIODS_ACTION_TYPES.SET_DATA_PERIOD_SETTINGS,
    data: { propertyName, propertyValue },
});

export const setDefaultDayPartSettings = () => ({
    type: TIME_PERIODS_ACTION_TYPES.SET_DEFAULT_DAY_PART_SETTINGS,
});

export const setDefaultDayTypeSettings = () => ({
    type: TIME_PERIODS_ACTION_TYPES.SET_DEFAULT_DAY_TYPE_SETTINGS,
});

export const setDataMonths = dataMonths => (dispatch, getState) => {
    const state = getState();
    const censusMixingPermitted = getCensusMixingPermitted(state);
    const isTruckVolumeAnalysis = getIsUSTruckVolumeAnalysis(state);
    const analysisTypeCode = getAnalysisTypeCode(state);
    const { travelModeCode } = getBasics(state);
    const isNetworkPerformanceAnalysis = getIsNetworkPerformanceAnalysis(analysisTypeCode);
    const isCVDAnalysisWithLimitedTravelerAttrs = getIsCVDAnalysisWithLimitedTravelerAttrs(
        analysisTypeCode,
        travelModeCode,
    );
    const isTimePeriodWithCVDLimitedTravelerAttrs =
        getHasTimePeriodWithCVDLimitedTravelerAttrs(dataMonths);

    const newValidation = {
        dataMonths: validateDataMonthsSection({
            dataMonths,
            censusMixingPermitted,
            isTruckVolumeAnalysis,
            isNetworkPerformanceAnalysis,
        }),
    };

    batch(() => {
        dispatch({
            type: TIME_PERIODS_ACTION_TYPES.SET_DATA_MONTHS,
            data: { dataMonths },
        });
        dispatch(setTimePeriodsValidation(newValidation));

        if (isCVDAnalysisWithLimitedTravelerAttrs && !isTimePeriodWithCVDLimitedTravelerAttrs) {
            dispatch(setTravelerAttrEnabled(false));
        }
    });
};

export const setRecommendedDataMonths = () => (dispatch, getState) => {
    const state = getState();
    const dataMonths = getDefaultDataMonthsForMode(state);

    dispatch(setDataMonths(dataMonths));
};

export const setDataMonthForYear = year => (dispatch, getState) => {
    const state = getState();
    const dataMonths = getAvailableDataMonthsForMode(state);
    const dataMonthsForYear = dataMonths.filter(month => month.year === year);

    dispatch(setDataMonths(dataMonthsForYear));
};

export const setDefaultDataPeriodsAndMonths =
    (dataPeriods, dataMonths) => (dispatch, getState) => {
        const state = getState();

        if (isDateRangeFormat(state)) {
            dispatch(setDataPeriods(dataPeriods));
        } else {
            const isKFactorAnalysis = isAnalysisTypeOf(
                CREATE_ANALYSIS_TYPES.K_FACTOR_ESTIMATION.code,
                state,
            );
            const _dataMonths = isKFactorAnalysis
                ? getKFactorDefaultDataMonths(state)
                : dataMonths;
            dispatch(setDataMonths(_dataMonths));
        }
    };

export const cancelAllEditDataPeriods = () => (dispatch, getState) => {
    const state = getState();

    const { dataPeriods } = getDataPeriodSettings(state);

    const isCancelNeeded =
        dataPeriods &&
        dataPeriods.length &&
        dataPeriods.some(({ status }) => status === "editing" || status === "adding");

    if (!isCancelNeeded) return;

    const newDataPeriods = [];

    dataPeriods.forEach(dataPeriod => {
        if (dataPeriod.status === "editing") {
            // Discard the status and edit state.
            newDataPeriods.push({
                startDate: dataPeriod.startDate,
                endDate: dataPeriod.endDate,
            });
        } else if (dataPeriod.status !== "adding") {
            // Do not add data periods that are in the middle of being added.
            newDataPeriods.push(dataPeriod);
        }
    });

    dispatch(setDataPeriods(newDataPeriods));
};

export const cancelAllEditDayType = () => (dispatch, getState) => {
    const state = getState();

    const { dayTypes } = getDayTypeSettings(state);

    if (!dayTypes.some(dayType => dayType.editMode)) return;

    const newDayTypes = [];

    dayTypes.forEach(dayType => {
        if (!dayType.editMode || dayType.name) {
            newDayTypes.push({
                name: dayType.name,
                start: dayType.start,
                end: dayType.end,
            });
        }
    });

    dispatch({
        type: TIME_PERIODS_ACTION_TYPES.CANCEL_ALL_EDIT_DAY_TYPE,
        data: { dayTypes: newDayTypes },
    });
};

export const cancelAllEditDayPart = () => (dispatch, getState) => {
    const state = getState();

    const { dayParts } = getDayPartSettings(state);

    if (!dayParts.some(dayPart => dayPart.editMode)) return;

    const newDayParts = [];

    dayParts.forEach(dayPart => {
        if (!dayPart.editMode || dayPart.name) {
            newDayParts.push({
                name: dayPart.name,
                start: dayPart.start,
                end: dayPart.end,
            });
        }
    });

    dispatch({
        type: TIME_PERIODS_ACTION_TYPES.CANCEL_ALL_EDIT_DAY_PART,
        data: { dayParts: newDayParts },
    });
};

export const setTimePeriodsInitialData = timePeriodsData => ({
    type: TIME_PERIODS_ACTION_TYPES.SET_INITIAL_DATA,
    data: { timePeriodsData },
});

export const resetTimePeriodsReducer = timePeriodsSwitchConfig => dispatch => {
    if (timePeriodsSwitchConfig) {
        return dispatch(
            setTimePeriodsInitialData(
                merge({}, TIME_PERIODS_INITIAL_STATE, timePeriodsSwitchConfig),
            ),
        );
    }
    return dispatch({
        type: TIME_PERIODS_ACTION_TYPES.RESET_REDUCER,
    });
};

export const setAADTYear = aadtYear => ({
    type: TIME_PERIODS_ACTION_TYPES.SET_AADT_YEAR,
    data: { aadtYear },
});

export const setDefaultAADTYear =
    (shouldUpdateAADTYears, currentTravelModeCode) => (dispatch, getState) => {
        const state = getState();
        const availableAADTYears = getAvailableAADTYears(state);
        const { aadtYear } = getAadtSettings(state);
        const { aadtCalibrationYear } = getCalibrationSettings(state);
        const country = getAnalysisCountry(state);
        const { travelModeCode: _travelModeCode } = getBasics(state);

        const travelModeCode = currentTravelModeCode || _travelModeCode;

        if (!availableAADTYears?.length) return;

        const defaultYearByCountry =
            country === ORG_COUNTRIES.CA.code
                ? DEFAULT_AADT_YEAR_CA
                : travelModeCode === MODES_OF_TRAVEL.ALL_VEHICLES_BY_WEIGHT.code
                ? DEFAULT_ALL_VEHICLES_BY_WEIGHT_YEAR
                : DEFAULT_AADT_YEAR;

        const defaultAADTYear = availableAADTYears.includes(defaultYearByCountry)
            ? defaultYearByCountry
            : availableAADTYears[availableAADTYears.length - 1];

        dispatch({
            type: TIME_PERIODS_ACTION_TYPES.SET_DEFAULT_AADT_YEAR,
            data: { aadtYear: defaultAADTYear },
        });

        if (!shouldUpdateAADTYears) return;

        if (!availableAADTYears.includes(aadtYear)) {
            dispatch(setAADTYear(defaultAADTYear));
        }

        if (!availableAADTYears.includes(aadtCalibrationYear)) {
            dispatch(setAADTCalibrationYear(defaultAADTYear));
        }
    };
